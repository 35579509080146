import React from 'react'
import "./Header.css"
import Logo from "../../assets/logo.png"
import { Link } from 'react-scroll' 

const Header = () => {
  return (
    <div className="header">
        <img src={Logo}  alt="" className='logo'></img>
        <ul className='header-menu'>
          
            <li><Link to = "">Home</Link></li>
            <li><Link to = "programs" span={true}
            smooth={true}>Program</Link></li>
            <li><Link to = "join-us" span={true}
            smooth={true}>Why Join Us</Link></li>
            <li><Link to = "plans" span={true}
            smooth={true}>Plans</Link></li>
            
            <li><Link to = "testimonials" span={true}
            smooth={true}>Testimonials</Link></li>
        </ul>

    </div>
  )
}

export default Header